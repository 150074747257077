
import { Component, Vue } from '@/lib/decorator';
import moment from 'moment';

import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';

import { strings } from '@/lib/stringConst';
import SiteModule from '@/store/site';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import PaymentsModule from '@/store/finance/payments';
import { PaymentsModalParamsType } from '@/store/finance/payments/modalParams';
import { PaymentModel } from '@/interfaces/models/payout.interface';

@Component({
  components: {
    ModalBtnBottom,
    IconFont,
    FormInput,
    FormButton,
  },
  emits: ['closeModal'],
})
export default class PaymentsModalPayment extends Vue {
  payment = this.model.payment && parseInt(this.model.payment) > 0 ? this.model.payment : '';

  get dateEnd(): string {
    return moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value,
      'DD/MM/YY'
    ).format('DD.MM.YYYY');
  }

  get text(): Record<string, string> {
    return strings;
  }

  get modalParams(): PaymentsModalParamsType {
    return PaymentsModule.modalParams;
  }

  get model(): PaymentModel {
    return PaymentsModule.model;
  }

  get forPayout(): number {
    return PaymentsModule.currentItem.forPayout;
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  async save(): Promise<false | void> {
    if (SiteModule.isBlock) {
      return false;
    }

    if (!this.payment.length || +this.payment <= 0) {
      ResponseHandlerModule.showNotify({ message: 'Сумма выплаты не может быть меньше или равно нулю', type: 'fail' });

      return false;
    }

    SiteModule.setIsBlock(true);
    PaymentsModule.addNewPayment(+this.payment);
  }
}
