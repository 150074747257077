import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-payment-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_modal_btn_bottom = _resolveComponent("modal-btn-bottom")!

  return (_openBlock(), _createElementBlock("form", {
    class: "cp-payment-form-export",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_gui_form_checkbox, {
        modelValue: _ctx.createPayouts,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.createPayouts) = $event)),
        label: "Создать выплаты"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_modal_btn_bottom, {
      position: "end",
      class: "cp-margin__t--small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_form_button, {
          text: _ctx.text.export,
          outline: true,
          type: 'submit'
        }, null, 8, ["text"])
      ]),
      _: 1
    })
  ], 32))
}