
import { Component, Vue } from '@/lib/decorator';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';

import { strings } from '@/lib/stringConst';
import PaymentsExportModule from '@/store/finance/payments/export';
import { GuiFormCheckbox } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormCheckbox,
    ModalBtnBottom,
    FormButton,
  },
})
export default class PaymentsModalExport extends Vue {
  createPayouts = false;

  get text() {
    return strings;
  }

  send() {
    PaymentsExportModule.exportPayments(this.createPayouts);
  }
}
