
import { Component, Vue } from '@/lib/decorator';
import PaymentsModule from '@/store/finance/payments';

@Component({})
export default class PaymentsModalRequisites extends Vue {
  get requisitesTitles() {
    return PaymentsModule.requisitesTitles;
  }

  get currentItem() {
    return PaymentsModule.currentItem;
  }
}
