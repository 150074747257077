import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cp-shift-form" }
const _hoisted_2 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_3 = { class: "cp-title-primary" }
const _hoisted_4 = { class: "cp-text-secondary" }
const _hoisted_5 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_6 = { class: "cp-title-primary" }
const _hoisted_7 = { class: "cp-text-secondary" }
const _hoisted_8 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_9 = { class: "cp-title-primary" }
const _hoisted_10 = { class: "cp-text-secondary" }
const _hoisted_11 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_12 = { class: "cp-title-primary" }
const _hoisted_13 = { class: "cp-text-secondary" }
const _hoisted_14 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_15 = { class: "cp-title-primary" }
const _hoisted_16 = { class: "cp-text-secondary" }
const _hoisted_17 = { class: "cp-form__row cp-form-row-padding" }
const _hoisted_18 = { class: "cp-title-primary" }
const _hoisted_19 = {
  key: 0,
  class: "cp-text-secondary"
}
const _hoisted_20 = {
  key: 1,
  class: "cp-text-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.requisitesTitles[0].name), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentItem.inn), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.requisitesTitles[1].name), 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.currentItem.bank), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.requisitesTitles[2].name), 1),
      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.currentItem.paymentAccount), 1)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.requisitesTitles[3].name), 1),
      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.currentItem.ks), 1)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.requisitesTitles[4].name), 1),
      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.currentItem.bic), 1)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.requisitesTitles[5].name), 1),
      (_ctx.currentItem.cardNumber)
        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.currentItem.cardNumber), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_20, "Не указано"))
    ])
  ]))
}