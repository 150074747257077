
import { Component, Vue } from '@/lib/decorator';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormButton from '@/components/form/Button/index.vue';

import { strings } from '@/lib/stringConst';
import PaymentsModule from '@/store/finance/payments';
import SiteModule from '@/store/site';
import { prepareMoneyFormat } from '@/lib/Utils';
import moment from 'moment';

@Component({
  components: {
    ModalBtnBottom,
    IconFont,
    FormButton,
  },
  emits: ['closeModal'],
})
export default class PaymentsModalPayment extends Vue {
  get dateEnd() {
    return moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value,
      'DD/MM/YY'
    ).format('DD.MM.YYYY');
  }

  get text() {
    return strings;
  }

  get modalParams() {
    return PaymentsModule.modalParams;
  }

  get forPayout() {
    const sumPayment = PaymentsModule.model.payment;

    return prepareMoneyFormat(sumPayment.toString());
  }

  returnBefore() {
    this.$emit('closeModal');
  }

  save() {
    if (SiteModule.isBlock) {
      return false;
    }

    PaymentsModule.validateMultiplePayouts().then((isError) => {
      if (isError) {
        return;
      }

      SiteModule.setIsBlock(true);
      PaymentsModule.multiplePayouts();
    });
  }
}
