
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import AppFilterClearBtn from '@/layouts/partials/AppFilterClearBtn.vue';
import PaginationBlock from '@/components/Pagination.vue';
import ModalBlock from '@/components/Modal.vue';
import Payment from './_payment.vue';
import Payments from './_payments.vue';
import Requisites from './_requisites.vue';
import ExportModal from './_export.vue';
import IconFont from '@/components/icons/IconFont.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FormButton from '@/components/form/Button/index.vue';

import PaymentsModule, { RequisitesModalInterface } from '@/store/finance/payments';
import PaymentsExportModule, { PaymentsExportStatus } from '@/store/finance/payments/export';
import UserModule from '@/store/user';
import FinanceModule from '@/store/finance';
import AppTable from '@/components/ui/table/Table.vue';
import { StatusListInterface } from '@/interfaces/ui/table/status.interface';
import TextMoney from '@/components/table-items/TextMoney.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import TextNumber from '@/components/table-items/TextNumber.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PaymentsModalParamsType } from '@/store/finance/payments/modalParams';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { TableInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { GIGANT_INN } from '@/lib/util/consts';
import { minutesToTime } from '@/lib/Utils';
import TimeFormatter from '@/components/TimeFormatter.vue';
import { GuiLoader } from '@library/gigant_ui';

library.add(faFileAlt);

@Component({
  components: {
    GuiLoader,
    TimeFormatter,
    AppCol,
    AppRow,
    TextNumber,
    TextMoney,
    AppTable,
    TabsNav,
    DatePicker,
    AppFilterClearBtn,
    PaginationBlock,
    ModalBlock,
    Payment,
    Requisites,
    Payments,
    ExportModal,
    IconFont,
    AppHeaderMain,
    FormButton,
  },
})
export default class PaymentsMain extends Vue {
  value: string[] = [];

  statusList: StatusListInterface = {
    isAccountUpdated: '#6E258B',
    hasNotPayoutAndSelfEmployed: '#FFC42B',
    hasNotPayout: '#FF4B3A',
  };

  getStatus(row: Record<string, boolean>): { color: string; tooltip?: string } | undefined {
    if (row.isAccountUpdated) {
      return { color: this.statusList.isAccountUpdated, tooltip: 'Недавно поменяли реквизиты' };
    }

    if (row.hasNotPayoutAndSelfEmployed) {
      return { color: this.statusList.hasNotPayoutAndSelfEmployed, tooltip: 'Раньше не платили, но стал самозанятым' };
    }

    if (row.hasNotPayout) {
      return { color: this.statusList.hasNotPayout, tooltip: 'Раньше не платили' };
    }
  }

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get dataLoaded(): boolean {
    return PaymentsModule.dataLoaded;
  }

  get userCanEditPartner(): boolean {
    return UserModule.userHasPermission('CAN_EDIT_PARTNER');
  }

  get userCanReadLastExport(): boolean {
    return PaymentsModule.userCanReadLastExport;
  }

  get userCanExportPayments(): boolean {
    return PaymentsModule.userCanExportPayments;
  }

  get tabsNav(): TabsNavInterface {
    return FinanceModule.tabsNav;
  }

  get currentTab(): string {
    return FinanceModule.allTabs.payments.id;
  }

  get modalParams(): PaymentsModalParamsType {
    return PaymentsModule.modalParams;
  }

  get balance(): number {
    return PaymentsModule.balance;
  }

  get model(): Record<string, Record<string | number, string | number | Record<string, string | number | boolean>>> {
    return PaymentsModule.modalParams.dateRange.model as Record<
      string,
      Record<string | number, string | number | Record<string, string | number | boolean>>
    >;
  }

  get exportStatuses(): typeof PaymentsExportStatus {
    return PaymentsExportStatus;
  }

  get exportStatus(): PaymentsExportStatus {
    return PaymentsExportModule.status;
  }

  get settings(): PageInterface {
    return PaymentsModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return PaymentsModule.filter.filterSettings;
  }

  get isGigantPartnerUser(): boolean {
    return UserModule.partner?.inn === GIGANT_INN;
  }

  async resetFilters(): Promise<void> {
    await PaymentsModule.setPageToBegin();
    await PaymentsModule.clearSort();
    await PaymentsModule.filter.resetFilter();
    await PaymentsModule.getList();
  }

  async updateList(): Promise<void> {
    await PaymentsModule.setPageToBegin();
    await PaymentsModule.filter.updateFilter();
    await PaymentsModule.getList();
  }

  getTime(minutes: number): string {
    return minutesToTime(minutes);
  }

  checkIsOwnPartner(partnerUuid: string): boolean {
    return UserModule.partner?.uuid === partnerUuid;
  }

  selectableFunction(item: { isSelfEmployed: boolean; forPayout: number; partnerUuid: string }): boolean {
    return (
      (!this.isGigantPartnerUser || item.isSelfEmployed) && item.forPayout > 0 && this.checkIsOwnPartner(item.partnerUuid)
    );
  }

  selectBy(item: { uuid: string }): string {
    return item.uuid;
  }

  change(value: never[]): void {
    this.value = value;
  }

  sort(header: { id: string; sort: { value: string } }): void {
    PaymentsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  openRequisitesModal(item: RequisitesModalInterface): void {
    PaymentsModule.openRequisitesModal(item);
  }

  openPaymentModal(item: RequisitesModalInterface): void {
    PaymentsModule.openPaymentModal(item);
  }

  selectAmount(value: string): void {
    PaymentsModule.updatePageAmountItems(value);
  }

  selectDateEnd(date: { day: string; month: string; year: string }): void {
    PaymentsModule.updateDateEnd(`${date.day}/${date.month}/${date.year}`);
  }

  selectDateStart(date: { day: string; month: string; year: string }): void {
    PaymentsModule.updateDateStart(`${date.day}/${date.month}/${date.year}`);
  }

  downloadLastExport(): void {
    PaymentsExportModule.downloadExportFile();
  }

  openModalExport(): void {
    PaymentsModule.showModal('export');
  }

  openModalAddAll(): void {
    let sumPayout = this.value.reduce((sumPayout: number, uuid: string) => {
      const { rows } = this.settings.table as TableInterface;
      const item = rows.find((el: TableRow) => el.uuid === uuid);
      const forPayoutValue = (item as TableRow).forPayout;

      return sumPayout + Math.ceil(forPayoutValue);
    }, 0);

    PaymentsModule.SET_SELECTED(this.value);
    PaymentsModule.openModalAddAll({ sumPayout, count: this.value.length });

    this.value = PaymentsModule.selectedEmployees;
  }

  closeModal(): void {
    PaymentsModule.hideModal('requisites');
    PaymentsModule.hideModal('payment');
    PaymentsModule.hideModal('payments');
    PaymentsModule.hideModal('export');
  }

  mounted(): void {
    FinanceModule.initTabsItems();
    PaymentsModule.init();
  }
}
